import { useState, useContext } from 'react';
import { defaultDeviceParams, lookupNameToId } from '../../helpers/Helpers';
import { LookupValueContext } from '../../contexts/LookupValueContext';

export const SourceTypeSelectCell = ({ row, onRowChange }) => {
  const lookupValues = useContext(LookupValueContext);
  const [selectedSourceType, setSelectedSourceType] = useState(
    row.SourceType || 'Internal',
  );
  return (
    <div style={{ margin: 'auto', width: '50%' }}>
      <select
        value={selectedSourceType}
        onChange={(e) => {
          setSelectedSourceType(e.target.value);

          onRowChange({
            ...row,
            SourceType: e.target.value,
            SourceTypeId: lookupNameToId(
              e.target.value,
              'TagSourceType',
              lookupValues,
            ),
            DeviceParams: defaultDeviceParams[e.target.value],
          });
        }}
      >
        <option value="HardWiredIO">HardWiredIO</option>
        <option value="J1939">J1939</option>
        <option value="ModbusTCP">ModbusTCP</option>
        <option value="ModbusRTU232">ModbusRTU232</option>
        <option value="ModbusRTU485">ModbusRTU485</option>
        <option value="SystemMetric">SystemMetric</option>
        <option value="Internal">Internal</option>
        <option value="CDL">CDL</option>
        <option value="FunctionLibrary">Function Library</option>
        <option value="MassaM3">MassaM3</option>
      </select>
    </div>
  );
};
