import Axios from 'axios';
import { tagToTagLayerViewFormat, tagToTagViewFormat } from './Helpers';

const API_BASE = process.env.REACT_APP_API_BASE;

const defaultAxiosOpts = {
  baseURL: API_BASE,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
  withCredentials: true,
  maxRedirects: 0,
};

// modify defaults if running in dev mode
if (
  process.env.REACT_APP_CI ||
  !process.env.NODE_ENV ||
  process.env.NODE_ENV !== 'production'
) {
  if (process.env.REACT_APP_API_BASIC_AUTH) {
    defaultAxiosOpts.withCredentials = false;
    defaultAxiosOpts.headers.Authorization =
      process.env.REACT_APP_API_BASIC_AUTH;
  }
}

const axiosInstance = Axios.create(defaultAxiosOpts);

axiosInstance.interceptors.response.use(
  async (response) => {
    if (response.status === 200) {
      if (response.headers['enbase-auth-redirect']) {
        window.location.href = response.headers['enbase-auth-redirect'];
      }
    }
    return response;
  },
  async (error) => {
    const response = error.response;
    if (response && response.status === 401) {
      const tcUrl = window.location.href;
      const cutoff = response.headers.location.substring(
        0,
        response.headers.location.indexOf('?ReturnUrl=') + 11,
      );
      const redirect_url = cutoff + encodeURIComponent(tcUrl);
      axiosInstance
        .get(redirect_url)
        .then((resp) => {
          console.log(redirect_url, resp);
        })
        .catch((err) => {
          console.error(redirect_url, err);
        });
    }
    return Promise.reject(error);
  },
);

export const getTagsByAssetIds = async (assetIdList) => {
  try {
    const body = { tagQuery: [], assetNumbers: [], assetIds: [...assetIdList] };
    let response = await axiosInstance.post('/api/tags/find', body);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getTagsByAssetNumbers = async (assetNumbers) => {
  try {
    const body = {
      tagQuery: [],
      assetNumbers: [...assetNumbers],
      assetIds: [],
    };
    let response = await axiosInstance.post('/api/tags/find', body);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getTagsWithFilter = async (filterObject) => {
  try {
    const body = {
      tagQuery: [...filterObject],
    };
    let response = await axiosInstance.post('/api/tags/find', body);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const saveTag = async (tag) => {
  try {
    const body = tagToTagViewFormat(tag);
    return axiosInstance.post('/api/tags/save', body);
  } catch (err) {
    throw new Error(err);
  }
};

export const saveTags = async (tagList) => {
  try {
    let tagSavePromises = [];
    for (const tag of tagList) {
      let promise = saveTag(tag);
      tagSavePromises.push(promise);
    }
    const proms = await Promise.allSettled(tagSavePromises);
    return proms;
  } catch (err) {
    throw new Error(err);
  }
};

export const saveTagList = async (tagList) => {
  try {
    const body = {
      ...tagList,
    };
    return axiosInstance.post('/api/taglists/save', body);
  } catch (err) {
    throw new Error(err);
  }
};

export const saveTagListAndTags = async (tagList, tags) => {
  try {
    const tagLayersSaved = await saveTagLayers(tags);
    const TCTagLayerIds = tagLayersSaved.map((tagLayerResponse) => {
      return tagLayerResponse.value.data.TCTagLayerId;
    });
    const body = {
      ...tagList,
      TagLayers: TCTagLayerIds,
    };
    return axiosInstance.post('/api/taglists/save', body);
  } catch (err) {
    throw new Error(err);
  }
};

export const getTagListById = async (TCTagListId) => {
  try {
    let response = await axiosInstance.get(`/api/taglists/${TCTagListId}`);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

//TO-DO: Returns slightly different object
export const getAllTagListNames = () => {
  try {
    let response = axiosInstance.get(`/api/taglists/list`);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const saveTagLayer = async (tagLayerObj) => {
  try {
    let body = tagToTagLayerViewFormat(tagLayerObj);
    return axiosInstance.post('/api/taglayers/save', body);
  } catch (err) {
    throw new Error(err);
  }
};

export const saveTagLayers = async (tagLayerList) => {
  try {
    let tagSavePromises = [];
    for (const tagLayer of tagLayerList) {
      let promise = saveTagLayer(tagLayer);
      tagSavePromises.push(promise);
    }
    const proms = await Promise.allSettled(tagSavePromises);
    return proms;
  } catch (err) {
    throw new Error(err);
  }
};

export const deployTagToAsset = (tag, assetId, assetNumber) => {
  try {
    let body = tagToTagViewFormat(tag);
    body.AssetTagId = 0;
    body.AssetId = assetId;
    body.AssetNumber = assetNumber;
    body.IsDirty = true;
    if (body.DisplayFormat) {
      // Value for DisplayFormat will get set by the backend
      delete body.DisplayFormat;
    }
    return axiosInstance.post('/api/tags/save', body);
  } catch (err) {
    throw new Error(err);
  }
};

export const deployTagsToAssets = async (deployList, tags, setDeployList) => {
  try {
    let tagSavePromises = [];
    for (const deploy of deployList) {
      let promise = deployTagToAsset(
        tags[deploy.TagIndex],
        deploy.AssetId,
        deploy.AssetNumber,
      );
      tagSavePromises.push(promise);
    }
    const proms = await Promise.allSettled(tagSavePromises);
    let newDeployList = [...deployList];
    proms.forEach((prom, index) => {
      if (prom.status === 'rejected') {
        newDeployList[index].DeployStatus = 'Failed';
        newDeployList[index].ErrorMessage = prom.reason;
      } else {
        newDeployList[index].DeployStatus = 'Complete';
      }
    });
    setDeployList(newDeployList);
    return proms;
  } catch (err) {
    throw new Error(err);
  }
};

export const getAssetsWithFilter = async (filterObject) => {
  try {
    const body = {
      tagQuery: [...filterObject],
      assetNumbers: [],
      assetIds: [],
    };
    let response = await axiosInstance.post('/api/assets/find', body);
    // Need assetId and assetNumber
    const idsOnly = response.data.map((asset) => {
      return {
        AssetId: asset.AssetId,
        AssetNumber: asset.AssetNumber,
      };
    });
    return idsOnly;
  } catch (err) {
    throw new Error(err);
  }
};

//TO-DO: Response looks slightly different, but all the same pieces
export const getLookupValues = async () => {
  try {
    let response = axiosInstance.get(
      '/api/lookups/list?name=TagType&name=TagSourceType&name=TagAlarmType&name=AlarmSeverity&name=GaugeType&name=TagDataType&name=NotificationPriority&name=AlarmArmingType&name=AlarmLimitRange',
    );
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

//TO-DO: Field name in response changed from 'Name' to 'Title'
export const getAllCodeLegends = async () => {
  try {
    let response = axiosInstance.get(`/api/codeLegends/list`);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getCodeLegendById = async (id) => {
  try {
    let response = await axiosInstance.get(`/api/codeLegends/${id}`);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getCodeLegendEntriesById = async (id) => {
  try {
    const response = await getCodeLegendById(id);
    return response.data.Entries;
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteTagListAndTags = async (id) => {
  try {
    const response = await axiosInstance.delete(
      `/api/taglists/${id}?deleteTagLayers=true`,
    );
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getAvailableTenants = async () => {
  try {
    let response = axiosInstance.get(`/DetechtionAccount/GetAvailableTenants`);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const changeSessionTenant = async (id) => {
  try {
    let response = axiosInstance.get(
      `/DetechtionAccount/ChangeSessionTenant/?desiredTenantId=${id}`,
    );
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getAvailablePermissions = async () => {
  try {
    let response = axiosInstance.get('/Account/GetAvailablePermissions');
    return response;
  } catch (err) {
    throw new Error(err);
  }
};