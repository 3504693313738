import { DataGrid } from '@mui/x-data-grid';
import { Paper, Typography, Divider, Box, Grid, Button } from '@mui/material';
import { deployTagsToAssets } from '../helpers/APIHelpers';
import { useEffect, useState } from 'react';
import { UploadFile } from '@mui/icons-material';
import { useDarkMode } from '../contexts/GlobalContext';

const TagDeployer = ({
  tags,
  targets,
  setInfoMessages,
  setConfirmationOnClose,
  setConfirmationText,
  setConfirmationTitle,
  setShowConfirmDeployDialog,
}) => {
  const [deployList, setDeployList] = useState([]);

  const darkModeState = useDarkMode();

  useEffect(() => {
    let newDeployList = [];
    let tagIndex = 0;
    let rowId = 1;
    for (const tag of tags) {
      for (const target of targets) {
        let deployObj = {
          id: rowId,
          TagIndex: tagIndex,
          AssetNumber: target.AssetNumber,
          AssetId: target.AssetId,
          TagName: tag.TagName,
          DeployStatus: 'Pending',
          ErrorMessage: '',
        };
        newDeployList.push(deployObj);
        rowId += 1;
      }
      tagIndex += 1;
    }
    setDeployList(newDeployList);
  }, [tags, targets]);

  const deploy = async () => {
    // Deploy tags to the specified assets
    try {
      let confirmationText = targets.map((target) => target.AssetNumber.toString()).join(', ');
      setConfirmationText(
        'Tags in the grid will deploy to the following assets: ' +
          confirmationText,
      );
      setConfirmationTitle('Confirm Deployment');
      const onClose = async (result) => {
        setShowConfirmDeployDialog(false);
        if (result === true) {
          setInfoMessages({
            type: 'snackbar',
            severity: 'info',
            message:
              'Tags in the grid have been queued for deployment to the specified assets.',
            open: true,
          });
          const res = await deployTagsToAssets(deployList, tags, setDeployList);
          const success = [];
          const fails = [];
          res.forEach((item) => {
            if (item.status === 'rejected') {
              fails.push(item);
            } else {
              success.push(item);
            }
          });

          if (fails.length === 0) {
            setInfoMessages({
              message: 'Tags deployed successfully.',
              severity: 'success',
              type: 'snackbar',
              open: true,
            });
          } else {
            const tagsFailed = fails.map((item) => {
              const reason = item.reason;
              const dataObj = JSON.parse(reason?.config?.data);
              return {
                TagName: dataObj.TagName,
                AssetNumber: dataObj.AssetNumber,
                reason: reason.code,
              };
            });
            const failureStrings = tagsFailed.map((item) => {
              return `TagName: ${item.TagName}, AssetNumber: ${item.AssetNumber}, Reason: ${item.reason}`;
            });
            setInfoMessages({
              type: 'dialog',
              message: (
                <div>
                  There was an error deploying some of your tags. The following
                  tags did not deploy properly:
                  <br />
                  {failureStrings.map((str) => {
                    return (
                      <>
                        {str}
                        <br />
                      </>
                    );
                  })}
                </div>
              ),
              title: 'Deployment Failures',
              open: true,
            });
          }
        }
      };
      setConfirmationOnClose(() => onClose);
      setShowConfirmDeployDialog(true);
    } catch (error) {
      console.error(error);
      setInfoMessages({
        type: 'snackbar',
        message:
          'There was an issue deploying tags. Please try again or contact an administrator.',
        severity: 'error',
        open: true,
      });
    }
  };

  const columnModel = [
    {
      field: 'AssetNumber',
      headerName: 'AssetNumber',
      flex: 0.5,
      type: 'string',
      editable: false,
    },
    {
      field: 'TagName',
      headerName: 'Tag Name',
      flex: 0.5,
      type: 'string',
      editable: false,
    },
    {
      field: 'DeployStatus',
      headerName: 'Deploy Status',
      flex: 0.5,
      type: 'string',
      editable: false,
    },
    {
      field: 'ErrorMessage',
      headerName: 'Error Message',
      flex: 1,
      type: 'string',
      editable: false,
    },
  ];

  return (
    <Paper sx={{ p: 2, my: 1, minHeight: 100 }}>
      <Typography gutterBottom variant="h5" component="div" color="primary">
        Deploy Tags
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Box>
        <DataGrid
          sx={{
            '--DataGrid-containerBackground': darkModeState
              ? '#3574e3'
              : '#017dba',
          }}
          rows={deployList}
          columns={columnModel}
          columnBuffer={5} // Default # of visible columns
        />
      </Box>
      <Divider sx={{ my: 2 }} />
      <Grid container justifyContent={'flex-end'}>
        <Button
          onClick={() => {
            deploy();
          }}
          startIcon={<UploadFile />}
          variant="contained"
        >
          Deploy Tags
        </Button>
      </Grid>
    </Paper>
  );
};

export default TagDeployer;
