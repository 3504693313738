import { useRef, useEffect, useContext, useState, useMemo } from 'react';
import { LookupValueContext } from '../contexts/LookupValueContext';
import { getScriptTemplatesByDistro } from '../helpers/FunctionLibraryTemplates';
import { getCodeLegendById } from '../helpers/APIHelpers';
import CodeLegendSelector from './CodeLegendSelector';
import { CodeLegendContext } from '../contexts/CodeLegendContext';
import { getLookupIDs, getLookupValues } from '../helpers/Helpers';

const divBase = (divRef) => {
  return (
    <div>
      <div
        id="common-tag-container"
        className="dialog-content tag-conf"
        title="Tag Configuration"
        ref={divRef}
      ></div>
    </div>
  );
};

export const SharedTagDialog = ({
  open,
  setOpen,
  tag,
  updateTag,
  setLoading,
  setInfoMessages,
  dialogId,
  distro,
  existingTags,
  setTag,
}) => {
  const divRef = useRef(null);
  const lookupValues = useContext(LookupValueContext);
  const codeLegends = useContext(CodeLegendContext);

  // State for code legend modal
  const [openCodeLegendSelect, setOpenCodeLegendSelect] = useState(false);

  const [selectedCodeLegend, setSelectedCodeLegend] = useState(null);

  const codeLegendModal = useMemo(() => {
    return (
      <CodeLegendSelector
        open={openCodeLegendSelect}
        onClose={(value) => {
          setOpenCodeLegendSelect(false);
          setSelectedCodeLegend(value);
          window
            .$('input[id="serverProperties.StatusAlarmLookupId"]')
            .val(value.value);
          window
            .$('input[id="serverProperties.StatusAlarmLookupId"]')
            .attr('input-value', value.id);
        }}
        sel={selectedCodeLegend}
        setSel={setSelectedCodeLegend}
      />
    );
  }, [openCodeLegendSelect, selectedCodeLegend]);

  useEffect(() => {
    if (open) {
      const buildTagTypeDictionary = function (lookupName) {
        let lookupItems = {};
        for (let i = 0; i < lookupValues.length; i++) {
          let lk = lookupValues[i];
          if (lk.LookupName === lookupName) {
            for (let j = 0; j < lk.Values.length; j++) {
              lookupItems[lk.Values[j].LookupValueId] = {
                InternalName: lk.Values[j].InternalName,
                DisplayName: lk.Values[j].DisplayName,
              };
            }
          }
        }
        return lookupItems;
      };

      const getChoiceDictionary = function (lookupName) {
        if (lookupName === 'AssetNumbers') {
          return {};
        } else {
          return buildTagTypeDictionary(lookupName);
        }
      };

      const lookupNameToId = function (value, category) {
        return getLookupIDs([value], category, lookupValues)[0];
      };

      const lookupIdToName = function (id, category) {
        let name = getLookupValues(category, id, lookupValues);
        if (Array.isArray(name)) {
          //For some reason a blank array is returned if ID not found
          return '';
        } else {
          return name;
        }
      };

      const blocker = {
        block: function (_) {
          setLoading(true);
        },
        unblock: function () {
          setLoading(false);
        },
      };

      const getCodeLegendTitle = (id) => {
        const legend = codeLegends.find(
          (cl) => cl.id.toString() === id.toString(),
        );
        return legend.label;
      };

      const getDescForStatusAlarmLookupId = (id, input) => {
        if (codeLegends.length > 1) {
          input.val(getCodeLegendTitle(id));
        } else {
          let codeLegend = getCodeLegendById(id);
          input.val(codeLegend.data.Title);
        }
      };

      // Propagates changes made in the shared tag dialog to the tag grid
      const saveTagRecord = (unconvertedTag, success, failure) => {
        let convertedTag =
          window.Enbase.Common.Tag.Normalization.ConvertTagFromHubInterfaceToDashboard(
            unconvertedTag,
          );
        try {
          // Reset fields that should not be changed that may have been changed in the tag object
          if (tag.TCTagLayerId) {
            convertedTag.TCTagLayerId = tag.TCTagLayerId;
          } else {
            convertedTag.TagId = tag.TagId;
          }
          if (convertedTag.SourceTypeId) {
            convertedTag.SourceTypeId = parseInt(convertedTag.SourceTypeId);
            convertedTag.SourceType = lookupIdToName(
              convertedTag.SourceTypeId,
              'TagSourceType',
            );
          }
          convertedTag.AssetId = tag.AssetId;
          convertedTag.AssetNumber = tag.AssetNumber;
          convertedTag.id = dialogId;
          // Put something valid in DisplayFormat so that if the tag is reopened it shows as a Text display tag, not number
          if (convertedTag.StatusAlarmLookupId && !convertedTag.DisplayFormat) {
            convertedTag.DisplayFormat = '{1,No Fault,N}';
          }
          // Update dynamic properties
          convertedTag.DynamicProperties = JSON.stringify(
            convertedTag.ParsedDynamicProperties,
          );
          // Update data type
          if (convertedTag.DataTypeId) {
            convertedTag.DataTypeId = parseInt(convertedTag.DataTypeId);
            convertedTag.DataType = lookupIdToName(
              convertedTag.DataTypeId,
              'TagDataType',
            );
          }
          updateTag(convertedTag);
          setTag(convertedTag);
          success(convertedTag);
          const msg = {
            type: 'snackbar',
            open: true,
            severity: 'info',
            message: 'Changes saved to grid.',
          };
          setInfoMessages(msg);
        } catch (e) {
          failure(convertedTag);
          const errNotice = {
            type: 'snackbar',
            open: true,
            severity: 'error',
            message: 'An error occurred when saving tag changes to the grid.',
          };
          if (e.message?.toLowerCase().includes('network')) {
            errNotice.message =
              'The application has lost connection to the internet.';
          }
          setInfoMessages(errNotice);
        }
      };

      const showServerLegendEditor = (_) => {
        let selectedLegend = codeLegends.find(
          (cl) =>
            cl.id.toString() ===
            window
              .$('input[id="serverProperties.StatusAlarmLookupId"]')
              .attr('input-value'),
        );
        setSelectedCodeLegend(selectedLegend);
        setOpenCodeLegendSelect(true);
      };

      // _contextOptions in the shared tag dialog
      const options = {
        DistributionVersion: parseFloat(distro), // Some functionality locked behind 3.1
        dom: window.$(divRef.current),
        DeviceStatus: null,
        AppOutputs: null,
        SelectMenus: false,
        SaveTagRecord: saveTagRecord,
        DeleteTagRecord: () => {},
        canManageAppAccess: false,
        FunctionAccessLevel: 75,
        MainDialogZIndex: null,
        RunningInDashboard: true,
        canManageServerProperties: true,
        ShowServerStrappingTableEditor: null, //Only called in server code
        ShowServerLegendEditor: showServerLegendEditor, //Only called in server code
        GetDescForStatusAlarmLookupId: getDescForStatusAlarmLookupId,
        GetDescForStrappingTableId: null, //Only called in server code
        IsChemicalView: false,
        IsReadOnly: false,
        ShowSaveButton: true,
        allowTagDelete: false,
        GetChoiceDictionary: getChoiceDictionary,
        ExistingTags: existingTags,
        LookupIdToName: lookupIdToName,
        LookupNameToId: lookupNameToId,
        ParentTagList: [],
        OnDialogClose: () => {
          setOpen(false);
        },
        Blocker: blocker,
        CalculatedTagTemplates: getScriptTemplatesByDistro(distro),
        TCMode: true,
      };

      if (tag.CodeLegendId && !tag.StatusAlarmLookupId) {
        tag.StatusAlarmLookupId = tag.CodeLegendId;
      }

      let convertedTag =
        window.Enbase.Common.Tag.Normalization.ConvertTagFromDashboardToHubInterface(
          tag,
          lookupNameToId,
        );
      const dialog = new window.Enbase.Common.Tag.MegaEditor(options);

      // Prepare tag object for the shared tag dialog
      convertedTag.assetNumber = tag.AssetNumber;
      convertedTag.tagId = tag.TagIdentifier;
      if (tag.SourceType && !convertedTag.sourceType) {
        convertedTag.sourceType = lookupNameToId(
          tag.SourceType,
          'TagSourceType',
        );
      }

      if (tag.DataType && !convertedTag.dataType) {
        convertedTag.dataType = lookupNameToId(tag.DataType, 'TagDataType');
      }

      if (!tag.AlarmType) {
        convertedTag.alarmType = null;
      } else {
        convertedTag.alarmType = lookupNameToId(tag.AlarmType, 'TagAlarmType');
      }

      if (!convertedTag.tagId) {
        convertedTag.tagId = -1;
      }

      if (!convertedTag.assetNumber) {
        convertedTag.assetNumber = 'Tag Configurator';
      }

      if (dialog.initialized) {
        dialog.show(convertedTag);

        // Disable fields incompatible with the tag dialog
        window.$('input#assetNumber').val(convertedTag.assetNumber);
        window.$('button:contains("Delete Tag")').prop('disabled', true);
        window.$('button:contains("Delete Tag")').remove();
        window
          .$('button:contains("Edit Strapping Table")')
          .prop('disabled', true);
        window.$('input#assetNumber').prop('disabled', true);
        window.$('select#alarm2Type').prop('disabled', true);
        // window.$('input[value="Choose Code Legend"]').prop('disabled', true);
        window.$('button:contains("Export Function")').prop('disabled', true);
        window.$('button:contains("Import Function")').prop('disabled', true);
        // window.$('input#tagId').prop('disabled', false); // Not sure if we want to do this

        // If the original tag data has a tag ID present, it is an existing tag in dashboard being edited. Do not let people change code legends in this case
        if (tag.TagId) {
          window.$('input[value="Choose Code Legend"]').prop('disabled', true);
        }
      }

      return () => {
        if (open === false) {
          window.$('#dialog-content').remove();
          return divBase(divRef);
        }
      };
    }
  }, [
    open,
    tag,
    setOpen,
    updateTag,
    lookupValues,
    setLoading,
    setInfoMessages,
    dialogId,
    distro,
    existingTags,
    codeLegends,
    setTag,
  ]);

  return (
    <>
      {divBase(divRef)}
      {codeLegendModal}
    </>
  );
};
