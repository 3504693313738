import { DataGrid, SelectColumn, textEditor } from 'react-data-grid';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { optionalColumns, getComparator } from '../helpers/Helpers';
import {
  Button,
  Divider,
  FormControl,
  InputLabel,
  Select,
  Stack,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  IconButton,
} from '@mui/material';
import { Delete, CopyAll, Edit } from '@mui/icons-material';
import {
  PlusOneOutlined,
  DeleteOutline,
  UndoOutlined,
} from '@mui/icons-material';
import SelectDistro from './SelectDistro';
import { DeviceInfoCell } from './GridCells/DeviceInfoCell';
import { DisplayFormatCell } from './GridCells/DisplayFormatCell';
import { TransformCell } from './GridCells/TransformCell';
import { ModbusRegisterCell } from './GridCells/ModbusRegisterCell';
import { ModbusIPCell } from './GridCells/ModbusIPCell';
import { ModbusPortCell } from './GridCells/ModbusPortCell';
import { ModbusSlaveIdCell } from './GridCells/ModbusSlaveIdCell';
import { SourceTypeSelectCell } from './GridCells/SourceTypeSelectCell';
import _ from 'lodash';

const TagGridNew = ({
  data,
  tags,
  setData,
  distro,
  setDistro,
  addNewEmptyTag,
  clearGrid,
  resetChanges,
  deleteRow,
  duplicateTag,
  openTagDialog,
  selectedTags,
  setSelectedTags,
  setInfoMessages,
  dirtyTags,
  setDirtyTags,
}) => {
  const [sortColumns, setSortColumns] = useState([]);

  const getCellClass = useCallback(
    (row, colKey) => {
      if (
        row &&
        tags &&
        tags[row.index] &&
        row[colKey] !== tags[row.index][colKey]
      ) {
        return 'editedCell';
      }
      return '';
    },
    [tags],
  );

  const leftColumns = useMemo(
    () => [
      SelectColumn,
      {
        key: 'TagName',
        name: 'Tag Name',
        renderEditCell: textEditor,
        cellClass: (row) => {
          return getCellClass(row, 'TagName');
        },
      },
      {
        key: 'TagIdentifier',
        name: 'Tag Identifier',
        renderEditCell: textEditor,
        cellClass: (row) => {
          return getCellClass(row, 'TagIdentifier');
        },
      },
    ],
    [getCellClass],
  );

  const rightColumns = useMemo(
    () => [
      {
        key: 'Actions',
        name: 'Actions',
        sortable: false,
        renderCell: ({ row }) => {
          return (
            <>
              <IconButton
                onClick={() => {
                  deleteRow(row.index);
                }}
              >
                <Delete />
              </IconButton>
              <IconButton
                onClick={() => {
                  duplicateTag(row.index);
                }}
              >
                <CopyAll />
              </IconButton>
              <IconButton
                onClick={() => {
                  openTagDialog(row.index);
                }}
              >
                <Edit />
              </IconButton>
            </>
          );
        },
      },
    ],
    [deleteRow, duplicateTag, openTagDialog],
  );

  const optionalColumnDefs = useMemo(() => {
    return {
      DisplayName: {
        key: 'DisplayName',
        name: 'Display Name',
        sortable: true,
        renderEditCell: textEditor,
        cellClass: (row) => {
          return getCellClass(row, 'DisplayName');
        },
      },
      UniversalDescriptor: {
        key: 'UniversalDescriptor',
        name: 'Universal Descriptor',
        sortable: true,
        renderEditCell: textEditor,
        cellClass: (row) => {
          return getCellClass(row, 'UniversalDescriptor');
        },
      },
      DeviceInfo: {
        key: 'DeviceInfo',
        name: 'Device Info',
        sortable: true,
        renderCell: ({ row }) => <DeviceInfoCell row={row} />,
      },
      ParentTagID: {
        key: 'ParentTagId',
        name: 'Parent Tag ID',
        sortable: true,
        renderEditCell: textEditor,
      },
      MaxValue: {
        key: 'MaxValue',
        name: 'Max Value',
        sortable: true,
        renderEditCell: textEditor,
        cellClass: (row) => {
          return getCellClass(row, 'MaxValue');
        },
      },
      MinValue: {
        key: 'MinValue',
        name: 'Min Value',
        sortable: true,
        renderEditCell: textEditor,
        cellClass: (row) => {
          return getCellClass(row, 'MinValue');
        },
      },
      Gain: {
        key: 'Gain',
        name: 'Gain',
        sortable: true,
        renderEditCell: textEditor,
        cellClass: (row) => {
          return getCellClass(row, 'Gain');
        },
      },
      Offset: {
        key: 'Offset',
        name: 'Offset',
        sortable: true,
        renderEditCell: textEditor,
        cellClass: (row) => {
          return getCellClass(row, 'Offset');
        },
      },
      AlarmLoLo: {
        key: 'AlarmLoLo',
        name: 'AlarmLoLo',
        sortable: true,
        renderEditCell: textEditor,
        cellClass: (row) => {
          return getCellClass(row, 'AlarmLoLo');
        },
      },
      AlarmLo: {
        key: 'AlarmLo',
        name: 'AlarmLo',
        sortable: true,
        renderEditCell: textEditor,
        cellClass: (row) => {
          return getCellClass(row, 'AlarmLo');
        },
      },
      AlarmHiHi: {
        key: 'AlarmHiHi',
        name: 'AlarmHiHi',
        sortable: true,
        renderEditCell: textEditor,
        cellClass: (row) => {
          return getCellClass(row, 'AlarmHiHi');
        },
      },
      AlarmHi: {
        key: 'AlarmHi',
        name: 'AlarmHi',
        sortable: true,
        renderEditCell: textEditor,
        cellClass: (row) => {
          return getCellClass(row, 'AlarmHi');
        },
      },
      DisplayFormat: {
        key: 'DisplayFormat',
        name: 'DisplayFormat',
        sortable: true,
        renderEditCell: textEditor,
        renderCell: ({ row }) => <DisplayFormatCell row={row} />,
        cellClass: (row) => {
          return getCellClass(row, 'DisplayFormat');
        },
      },
      UnitOfMeasure: {
        key: 'UnitOfMeasure',
        name: 'UnitOfMeasure',
        sortable: true,
        renderEditCell: textEditor,
        cellClass: (row) => {
          return getCellClass(row, 'UnitOfMeasure');
        },
      },
      LoggingFrequency: {
        key: 'LoggingFrequency',
        name: 'LoggingFrequency',
        sortable: true,
        renderEditCell: textEditor,
        cellClass: (row) => {
          return getCellClass(row, 'LoggingFrequency');
        },
      },
      GroupName: {
        key: 'GroupName',
        name: 'GroupName',
        sortable: true,
        renderEditCell: textEditor,
        cellClass: (row) => {
          return getCellClass(row, 'GroupName');
        },
      },
      IsActive: {
        key: 'IsActive',
        name: 'IsActive',
        sortable: true,
        renderEditCell: textEditor,
        cellClass: (row) => {
          return getCellClass(row, 'IsActive');
        },
      },
      Description: {
        key: 'Description',
        name: 'Description',
        sortable: true,
        renderEditCell: textEditor,
        cellClass: (row) => {
          return getCellClass(row, 'Description');
        },
      },
      IsWriteable: {
        key: 'IsWriteable',
        name: 'IsWriteable',
        sortable: true,
        renderEditCell: textEditor,
        cellClass: (row) => {
          return getCellClass(row, 'IsWriteable');
        },
      },
      Transform: {
        key: 'Transform',
        name: 'Transform',
        sortable: true,
        renderEditCell: textEditor,
        renderCell: ({ row }) => <TransformCell row={row} />,
        cellClass: (row) => {
          return getCellClass(row, 'Transform');
        },
      },
      ScanFrequency: {
        key: 'ScanFrequency',
        name: 'Scan Frequency',
        sortable: true,
        renderEditCell: textEditor,
        cellClass: (row) => {
          return getCellClass(row, 'ScanFrequency');
        },
      },
      DynamicProperties: {
        key: 'DynamicProperties',
        name: 'Dynamic Properties',
        sortable: true,
        renderEditCell: textEditor,
        cellClass: (row) => {
          return getCellClass(row, 'DynamicProperties');
        },
      },
      SourceType: {
        key: 'SourceType',
        name: 'Source Type',
        sortable: false,
        renderEditCell: ({ row, onRowChange, rowIdx }) => {
          return (
            <SourceTypeSelectCell
              row={row}
              onRowChange={onRowChange}
              rowIdx={rowIdx}
            />
          );
        },
        cellClass: (row) => {
          return getCellClass(row, 'SourceType');
        },
      },
      AlarmType: {
        key: 'AlarmType',
        name: 'Alarm Type',
        sortable: true,
        renderEditCell: textEditor,
        cellClass: (row) => {
          return getCellClass(row, 'AlarmType');
        },
      },
      ModbusRegister: {
        key: 'ModbusRegister',
        name: 'Modbus Register',
        sortable: false,
        renderEditCell: textEditor,
        renderCell: ({ row }) => <ModbusRegisterCell row={row} />,
      },
      DataType: {
        key: 'DataType',
        name: 'Data Type',
        sortable: true,
        renderEditCell: textEditor,
        cellClass: (row) => {
          return getCellClass(row, 'DataType');
        },
      },
      ModbusIP: {
        key: 'ModbusIP',
        name: 'Modbus IP Address',
        sortable: false,
        renderEditCell: textEditor,
        renderCell: ({ row }) => <ModbusIPCell row={row} />,
      },
      ModbusPort: {
        key: 'ModbusPort',
        name: 'Modbus Port',
        sortable: false,
        renderEditCell: textEditor,
        renderCell: ({ row }) => <ModbusPortCell row={row} />,
      },
      ModbusSlaveId: {
        key: 'ModbusSlave',
        name: 'Modbus Slave ID',
        sortable: false,
        renderEditCell: textEditor,
        renderCell: ({ row }) => <ModbusSlaveIdCell row={row} />,
      },
      DisplayOrder: {
        key: 'DisplayOrder',
        name: 'Display Order',
        sortable: true,
        renderEditCell: textEditor,
        cellClass: (row) => {
          return getCellClass(row, 'DisplayOrder');
        },
      },
    };
  }, [getCellClass]);

  const [selectedColumns, setSelectedColumns] = useState(
    JSON.parse(localStorage.getItem('visibleColumns')) || [],
  );

  const [columnStructure, setColumnStructure] = useState([
    ...leftColumns,
    ...rightColumns,
  ]);

  const handleFill = ({ columnKey, sourceRow, targetRow }) => {
    if (columnKey === 'ModbusRegister') {
      try {
        return {
          ...targetRow,
          ModbusRegister: JSON.parse(sourceRow.DeviceParams).regnum,
        };
      } catch (err) {
        return { ...targetRow };
      }
    } else if (columnKey === 'ModbusIP') {
      try {
        return {
          ...targetRow,
          ModbusIP: JSON.parse(sourceRow.DeviceParams).connection.ip,
        };
      } catch (err) {
        return { ...targetRow };
      }
    } else if (columnKey === 'ModbusPort') {
      try {
        return {
          ...targetRow,
          ModbusPort:
            sourceRow.SourceType === 'ModbusTCP'
              ? JSON.parse(sourceRow.DeviceParams).connection.port_number
              : JSON.parse(sourceRow.DeviceParams).connection.port,
        };
      } catch (err) {
        return { ...targetRow };
      }
    } else if (columnKey === 'ModbusSlave') {
      try {
        return {
          ...targetRow,
          ModbusSlave: JSON.parse(sourceRow.DeviceParams).connection.slave_id,
        };
      } catch (err) {
        return { ...targetRow };
      }
    } else return { ...targetRow, [columnKey]: sourceRow[columnKey] };
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedColumns(typeof value === 'string' ? value.split(',') : value);
    localStorage.setItem('visibleColumns', JSON.stringify(value));
  };

  useEffect(() => {
    const newCols = selectedColumns.map((colName) => {
      return optionalColumnDefs[colName];
    });
    setColumnStructure([...leftColumns, ...newCols, ...rightColumns]);
  }, [selectedColumns, leftColumns, rightColumns, optionalColumnDefs]);

  const rowsChange = (newData, changes) => {
    const modbusTypes = ['ModbusTCP', 'ModbusRTU232', 'ModbusRTU485'];
    if (changes.column.key === 'ModbusRegister') {
      for (const changedTag of changes.indexes) {
        if (!modbusTypes.includes(newData[changedTag].SourceType)) {
          setInfoMessages({
            open: true,
            message: 'Not a Modbus Tag',
            severity: 'error',
          });
          delete newData[changedTag].ModbusRegister;
          continue;
        }
        try {
          newData[changedTag].DeviceParams = JSON.stringify({
            ...JSON.parse(newData[changedTag].DeviceParams),
            regnum: newData[changedTag].ModbusRegister,
          });
        } catch (e) {
          setInfoMessages({
            open: true,
            message: 'Invalid DeviceParams',
            severity: 'error',
          });
        } finally {
          delete newData[changedTag].ModbusRegister;
        }
      }
    } else if (changes.column.key === 'ModbusIP') {
      for (const changedTag of changes.indexes) {
        if (!modbusTypes.includes(newData[changedTag].SourceType)) {
          setInfoMessages({
            open: true,
            message: 'Not a ModbusTCP Tag',
            severity: 'error',
          });
          delete newData[changedTag].ModbusIP;
          continue;
        }
        try {
          const oldDeviceParams = JSON.parse(newData[changedTag].DeviceParams);
          let newDeviceParams = { ...oldDeviceParams };
          newDeviceParams.connection.ip = newData[changedTag].ModbusIP;
          newData[changedTag].DeviceParams = JSON.stringify(newDeviceParams);
        } catch (err) {
          setInfoMessages({
            open: true,
            message: 'Invalid DeviceParams',
            severity: 'error',
          });
        } finally {
          delete newData[changedTag].ModbusIP;
        }
      }
    } else if (changes.column.key === 'ModbusSlave') {
      for (const changedTag of changes.indexes) {
        if (!modbusTypes.includes(newData[changedTag].SourceType)) {
          setInfoMessages({
            open: true,
            message: 'Not a Modbus Tag',
            severity: 'error',
          });
          delete newData[changedTag].ModbusSlave;
          continue;
        }
        try {
          const oldDeviceParams = JSON.parse(newData[changedTag].DeviceParams);
          let newDeviceParams = { ...oldDeviceParams };
          newDeviceParams.connection.slave_id = newData[changedTag].ModbusSlave;
          newData[changedTag].DeviceParams = JSON.stringify(newDeviceParams);
        } catch (err) {
          setInfoMessages({
            open: true,
            message: 'Invalid DeviceParams',
            severity: 'error',
          });
        } finally {
          delete newData[changedTag].ModbusSlave;
        }
      }
    } else if (changes.column.key === 'ModbusPort') {
      for (const changedTag of changes.indexes) {
        if (!modbusTypes.includes(newData[changedTag].SourceType)) {
          setInfoMessages({
            open: true,
            message: 'Not a Modbus Tag',
            severity: 'error',
          });
          delete newData[changedTag].ModbusPort;
          continue;
        }
        try {
          const oldDeviceParams = JSON.parse(newData[changedTag].DeviceParams);
          let newDeviceParams = { ...oldDeviceParams };
          if (newData[changedTag].SourceType === 'ModbusTCP') {
            newDeviceParams.connection.port = newData[changedTag].ModbusPort;
          } else {
            newDeviceParams.connection.port_number =
              newData[changedTag].ModbusPort;
          }
          newData[changedTag].DeviceParams = JSON.stringify(newDeviceParams);
        } catch (err) {
          setInfoMessages({
            open: true,
            message: 'Invalid DeviceParams',
            severity: 'error',
          });
        } finally {
          delete newData[changedTag].ModbusPort;
        }
      }
    }
    for (const row of changes.indexes) {
      let isDirty = dirtyTags.has(row);
      if (!_.isEqual(newData[row], tags[row])) {
        if (!isDirty) {
          let newDirtyTags = new Set(dirtyTags);
          newDirtyTags.add(row);
          setDirtyTags(newDirtyTags);
        }
      } else {
        if (isDirty) {
          let newDirtyTags = new Set(dirtyTags);
          newDirtyTags.delete(row);
          setDirtyTags(newDirtyTags);
        }
      }
    }
    setData(newData);
  };

  const sortedRows = useMemo(() => {
    if (sortColumns.length === 0) return data;

    return [...data].sort((a, b) => {
      for (const sort of sortColumns) {
        const comparator = getComparator(sort.columnKey);
        const compResult = comparator(a, b);
        if (compResult !== 0) {
          return sort.direction === 'ASC' ? compResult : -compResult;
        }
      }
      return 0;
    });
  }, [sortColumns, data]);

  const handleCellPaste = ({ row, column }, event) => {
    const targetColumnKey = column.key;

    return {
      ...row,
      [targetColumnKey]: event.clipboardData.getData('text/plain'),
    };
  };

  const handleCellCopy = ({ row, column }, event) => {
    event.clipboardData.setData('text/plain', row[column.key]);
    event.preventDefault();
  };

  return (
    <>
      <Stack direction={'row'} spacing={2} justifyContent={'right'}>
        <FormControl sx={{ width: 200 }}>
          <InputLabel id="column-select-label">Columns</InputLabel>
          <Select
            labelId="column-select-label"
            multiple
            value={selectedColumns}
            onChange={handleChange}
            input={<OutlinedInput label="Columns" />}
            renderValue={(selected) => selected.join(', ')}
            size={'small'}
          >
            {optionalColumns.map((col, index) => (
              <MenuItem key={index} value={col}>
                <Checkbox checked={selectedColumns.indexOf(col) > -1} />
                <ListItemText primary={optionalColumnDefs[col].name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <SelectDistro distro={distro} setDistro={setDistro} />
        <Button
          aria-label="Clear Grid"
          startIcon={<PlusOneOutlined />}
          onClick={() => {
            addNewEmptyTag(0);
          }}
          variant="contained"
        >
          Add New Tag
        </Button>
        <Button
          aria-label="Clear Grid"
          startIcon={<DeleteOutline />}
          onClick={() => clearGrid()}
          variant="contained"
        >
          Clear Grid
        </Button>
        <Button
          aria-label="Reset Changes"
          startIcon={<UndoOutlined />}
          onClick={() => resetChanges()}
          variant="contained"
        >
          Reset Changes
        </Button>
      </Stack>
      <Divider sx={{ my: 2 }} />
      <DataGrid
        key={columnStructure.length}
        columns={columnStructure}
        rows={sortedRows}
        className="rdg-light"
        defaultColumnOptions={{
          resizable: true,
          sortable: true,
        }}
        onRowsChange={rowsChange}
        selectedRows={selectedTags}
        onFill={handleFill}
        onSelectedRowsChange={setSelectedTags}
        isRowSelectionDisabled={() => {
          return false;
        }}
        rowKeyGetter={(row) => {
          return row.index;
        }}
        sortColumns={sortColumns}
        onSortColumnsChange={setSortColumns}
        onCellCopy={handleCellCopy}
        onCellPaste={handleCellPaste}
      />
    </>
  );
};

export default TagGridNew;
