import { useEffect, useState } from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { changeSessionTenant } from '../helpers/APIHelpers';
import { sortBy } from 'lodash';

const SelectTenant = ({ tenantInfo }) => {
  const [selectedTenant, setSelectedTenant] = useState('');

  useEffect(() => {
    if (tenantInfo.currentTenantId) {
      setSelectedTenant(tenantInfo.currentTenantId);
    }
  }, [tenantInfo]);

  return (
    <>
      <FormControl sx={{ width: 200 }}>
        <InputLabel id="tenant-label">Tenant</InputLabel>
        <Select
          labelId="tenant-label"
          value={selectedTenant}
          onChange={(e) => {
            changeSessionTenant(e.target.value)
              .then(() => {
                setSelectedTenant(e.target.value);
                window.location.reload(true);
              })
              .catch((e) => {});
          }}
          size={'small'}
          input={<OutlinedInput label="Tenant" />}
        >
          {tenantInfo.availableTenants &&
            sortBy(tenantInfo.availableTenants, (tenant) => {
              return tenant.DisplayName;
            }).map((tenant, index) => {
              return (
                <MenuItem key={index} value={tenant.TenantId}>
                  {tenant.DisplayName}
                </MenuItem>
              );
            })}
          {!tenantInfo.availableTenants && <MenuItem value={''}>None</MenuItem>}
        </Select>
      </FormControl>
    </>
  );
};

export default SelectTenant;
