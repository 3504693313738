const generateModbusRegisterCell = (row) => {
  const modbusTypes = ['ModbusTCP', 'ModbusRTU232', 'ModbusRTU485'];
  if (!modbusTypes.includes(row.SourceType)) {
    return 'Not a Modbus Tag';
  } else {
    try {
      const modbusRegister = JSON.parse(row.DeviceParams).regnum;
      return modbusRegister;
    } catch (e) {
      return 'Invalid DeviceParams';
    }
  }
};

export const ModbusRegisterCell = ({ row }) => {
  return <div>{generateModbusRegisterCell(row)}</div>;
};
