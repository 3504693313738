const generateModbusSlaveIdCell = (row) => {
  const modbusTypes = ['ModbusTCP', 'ModbusRTU232', 'ModbusRTU485'];
  if (!modbusTypes.includes(row.SourceType)) {
    return 'Not a Modbus Tag';
  } else {
    try {
      const modbusSlaveId = JSON.parse(row.DeviceParams).connection.slave_id;
      return modbusSlaveId;
    } catch (e) {
      return 'Invalid DeviceParams';
    }
  }
};

export const ModbusSlaveIdCell = ({ row }) => {
  return <div>{generateModbusSlaveIdCell(row)}</div>;
};
