import {
  CssBaseline,
  ThemeProvider,
  Backdrop,
  CircularProgress,
  Alert,
} from '@mui/material';
import './App.css';
import 'react-data-grid/lib/styles.css';
import { useDarkMode } from './contexts/GlobalContext';
import { lightTheme, darkTheme } from './components/DetechtionTheme';
import { Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  getAllCodeLegends,
  getAllTagListNames,
  getAvailablePermissions,
  getAvailableTenants,
  getLookupValues,
} from './helpers/APIHelpers';
import { TagListContext } from './contexts/TagListContext';
import { LookupValueContext } from './contexts/LookupValueContext';
import { CodeLegendContext } from './contexts/CodeLegendContext';
import { TenantContext } from './contexts/TenantContext';

export default function App() {
  const darkModeState = useDarkMode();

  const [tagLists, setTagLists] = useState([]);
  const [lookupValues, setLookupValues] = useState({});
  const [codeLegends, setCodeLegends] = useState([]);
  const [tenantInfo, setTenantInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [alertText, setAlertText] = useState('Missing Permission(s): ');
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    setLoading(true);
    getAvailablePermissions()
      .then((result) => {
        if (
          !result.data.permissions.find(
            (perm) => perm.PermissionName === 'LookupValueRead',
          )
        ) {
          setAlertText((a) => a + 'LookupValueRead ');
          setShowAlert(true);
          setLoading(false);
        }
        if (
          !result.data.permissions.find(
            (perm) => perm.PermissionName === 'CodeLegendRead',
          )
        ) {
          setAlertText((a) => a + 'CodeLegendRead ');
          setShowAlert(true);
          setLoading(false);
        }
        if (
          !result.data.permissions.find(
            (perm) => perm.PermissionName === 'CanUpdateBulkTags',
          )
        ) {
          setAlertText((a) => a + 'CanUpdateBulkTags ');
          setShowAlert(true);
          setLoading(false);
        }
      })
      .catch((e) => {});
    getAllTagListNames()
      .then((result) => {
        setTagLists({ TagLists: result.data });
        // setLoading(false);
      })
      .catch((e) => {});
    getLookupValues()
      .then((result) => {
        setLookupValues(result.data);
        setLoading(false);
      })
      .catch((e) => {});
    getAllCodeLegends()
      .then((result) => {
        let codeLegendDataList = result.data.map((legend) => {
          return {
            id: legend.CodeLegendId,
            value: legend.Title,
            label: legend.Title,
          };
        });
        setCodeLegends(codeLegendDataList);
      })
      .catch((e) => {});
    getAvailableTenants()
      .then((result) => {
        setTenantInfo(result.data);
      })
      .catch((e) => {});
  }, []);

  return (
    <ThemeProvider theme={darkModeState ? darkTheme : lightTheme}>
      <TagListContext.Provider value={tagLists}>
        <CodeLegendContext.Provider value={codeLegends}>
          <LookupValueContext.Provider value={lookupValues}>
            <TenantContext.Provider value={tenantInfo}>
              <Backdrop open={loading} sx={{ zIndex: 3 }}>
                <CircularProgress />
              </Backdrop>
              <CssBaseline />
              {showAlert && (
                <Alert variant="filled" severity="error">
                  {alertText}
                </Alert>
              )}
              <Outlet />
            </TenantContext.Provider>
          </LookupValueContext.Provider>
        </CodeLegendContext.Provider>
      </TagListContext.Provider>
    </ThemeProvider>
  );
}
