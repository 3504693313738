import { Paper, Typography, Divider, Grid, Button } from '@mui/material';
import { TravelExplore } from '@mui/icons-material';
import { useState } from 'react';
import { AssetSearchModal } from './AssetSearchModal';

const SourceSelection = ({
  setAssetTargetFilter,
  assetTargetFilter,
  setShowDeployGrid,
  setTargetAssets,
  setLoading,
  setInfoMessages,
}) => {
  const [assetSearchModalOpen, setAssetSearchModalOpen] = useState(false);

  return (
    <Paper sx={{ p: 2, my: 1, minHeight: 100 }}>
      <Typography gutterBottom variant="h5" component="div" color="primary">
        Target Selection
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="body1">
            Select where to deploy the tags in the grid, or save changes to the
            original source.
          </Typography>
        </Grid>
        <Grid container item xs={8} spacing={1} justifyContent={'flex-end'}>
          <Grid container item xs={'auto'} justifyContent={'flex-end'}>
            <AssetSearchModal
              open={assetSearchModalOpen}
              set
              onClose={async () => {
                setAssetSearchModalOpen(false);
              }}
              setAssetTargetFilter={setAssetTargetFilter}
              assetTargetFilter={assetTargetFilter}
              setTargetAssets={setTargetAssets}
              setLoading={setLoading}
              setShowDeployGrid={setShowDeployGrid}
              setInfoMessages={setInfoMessages}
            />
            <Button
              variant="contained"
              onClick={() => setAssetSearchModalOpen(true)}
              startIcon={<TravelExplore />}
            >
              Select Assets
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SourceSelection;
