const generateTransformCell = (row) => {
  const value = row.Transform ?? '';
  if (value.startsWith('scriptTemplate')) {
    const funcName =
      JSON.parse(value.substr(15)).templateName ?? 'Custom Function';
    return funcName;
  } else if (value.startsWith('table')) {
    return 'Table';
  } else if (value.startsWith('script')) {
    return 'Custom Function';
  } else return '';
};

export const TransformCell = ({ row }) => {
  return <div>{generateTransformCell(row)}</div>;
};
