import DragModal from './DragModal';
import AssetFilter from './AssetFilter';
import { Typography, Divider, DialogContent, Box } from '@mui/material';
import { getAssetsWithFilter } from '../helpers/APIHelpers';

export const AssetSearchModal = ({
  open,
  onClose,
  setAssetTargetFilter,
  assetTargetFilter,
  setLoading,
  setTargetAssets,
  setShowDeployGrid,
  setInfoMessages,
}) => {
  const handleAssetFilterActions = async (action) => {
    if (action.modalState === 'closed') {
      onClose();
    }
    if (action.filter) {
      setAssetTargetFilter(action.filter);
      setLoading(true);
      try {
        const assetsToTarget = await getAssetsWithFilter(action.filter);
        setTargetAssets(assetsToTarget);
        setShowDeployGrid(true);
      } catch (err) {
        setInfoMessages({
          type: 'snackbar',
          message:
            'There was an error retrieving assets. Please try again or contact administrator.',
          severity: 'error',
          open: true,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <DragModal
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      titlecomponent={
        <Typography variant="h5" color="primary">
          Select Target Assets
        </Typography>
      }
    >
      <Divider />
      <DialogContent>
        <Box sx={{ minWidth: 300 }}>
          <AssetFilter
            presetFilter={assetTargetFilter}
            assetFilterActions={handleAssetFilterActions}
          />
        </Box>
      </DialogContent>
    </DragModal>
  );
};
