// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editedCell {
  background-color: rgba(255, 255, 0, 0.5);
}
`, "",{"version":3,"sources":["webpack://./src/components/EditTabComponent.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;AAC1C","sourcesContent":[".editedCell {\n  background-color: rgba(255, 255, 0, 0.5);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
