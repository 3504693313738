const generateModbusPortCell = (row) => {
  const modbusTypes = ['ModbusTCP', 'ModbusRTU232', 'ModbusRTU485'];
  const sourceTypeName = row.SourceType;
  if (!modbusTypes.includes(sourceTypeName)) {
    return 'Not a Modbus Tag';
  } else {
    try {
      const modbusPort =
        sourceTypeName === 'ModbusTCP'
          ? JSON.parse(row.DeviceParams).connection.port
          : JSON.parse(row.DeviceParams).connection.port_number;
      return modbusPort;
    } catch (e) {
      return 'Invalid DeviceParams';
    }
  }
};

export const ModbusPortCell = ({ row }) => {
  return <div>{generateModbusPortCell(row)}</div>;
};
