const generateModbusIPCell = (row) => {
  if (row.SourceType !== 'ModbusTCP') {
    return 'Not a ModbusTCP Tag';
  } else {
    try {
      const modbusIP = JSON.parse(row.DeviceParams).connection.ip;
      return modbusIP;
    } catch (e) {
      return 'Invalid DeviceParams';
    }
  }
};

export const ModbusIPCell = ({ row }) => {
  return <div>{generateModbusIPCell(row)}</div>;
};
