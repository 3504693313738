const generateDeviceInfoCell = (row) => {
  if (!row || !row.SourceType) {
    return 'No Source';
  }
  const sourceTypeName = row.SourceType;

  // CDL
  if (sourceTypeName === 'CDL') {
    try {
      const cdlInfo = JSON.parse(row.DeviceParams);
      const value = `${cdlInfo.mid ?? ''}/${cdlInfo.pid ?? ''};${cdlInfo.mask ?? ''}`;
      return value;
    } catch (e) {
      return 'Invalid DeviceParams';
    }
  }
  // HWIO
  else if (sourceTypeName === 'HardWiredIO') {
    // HWIO channel numbers count from 0.
    const channelNumber = row.ChannelNumber
      ? Number(row.ChannelNumber) + 1
      : '';
    const value = `${row.PortNumber ?? ''}/${row.Type ?? ''}${channelNumber ?? ''}`;
    return value;
  }
  // ModbusRTU
  else if (
    sourceTypeName === 'ModbusRTU232' ||
    sourceTypeName === 'ModbusRTU485'
  ) {
    try {
      const modbusRTUInfo = JSON.parse(row.DeviceParams);
      const value = `${modbusRTUInfo.connection.slave_id ?? ''}/${modbusRTUInfo.regnum ?? ''};${modbusRTUInfo.connection.port_number ?? ''}`;
      return value;
    } catch (e) {
      return 'Invalid DeviceParams';
    }
  }
  // ModbusTCP
  else if (sourceTypeName === 'ModbusTCP') {
    try {
      const modbusTCPInfo = JSON.parse(row.DeviceParams);
      const value = `${modbusTCPInfo.connection.slave_id ?? ''}/${modbusTCPInfo.regnum ?? ''};${modbusTCPInfo.connection.ip ?? ''}:${modbusTCPInfo.connection.port ?? ''}`;
      return value;
    } catch (e) {
      return 'Invalid DeviceParams';
    }
  }
  // SystemMetric
  else if (sourceTypeName === 'SystemMetric') {
    return row.DeviceParams ?? '';
  }
  // FunctionLibrary
  else if (sourceTypeName === 'Function Library') {
    try {
      if (!row.Transform) {
        return 'Invalid Transform';
      }
      if (row.Transform.startsWith('scriptTemplate')) {
        const parsedJSON = JSON.parse(row.Transform.substr(15));
        const funcName = parsedJSON.templateName ?? 'Custom Function';
        return funcName;
      } else {
        return 'Custom Function';
      }
    } catch (e) {
      return 'Invalid Transform';
    }
  }
  // J1939
  else if (sourceTypeName === 'J1939') {
    try {
      const value = row.DeviceParams ?? '';
      return value;
    } catch (e) {
      return 'Invalid DeviceParams';
    }
  }
  // otherwise nothing
  return '';
};

export const DeviceInfoCell = ({ row }) => {
  return <div>{generateDeviceInfoCell(row)}</div>;
};
