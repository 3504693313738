const generateDisplayFormatCell = (row) => {
  const value = row.DisplayFormat ?? '';
  if (window.Enbase.Common.Tag.ParseCodeLegend(value)) {
    return 'Code Legend';
  } else {
    return value;
  }
};

export const DisplayFormatCell = ({ row }) => {
  return <div>{generateDisplayFormatCell(row)}</div>;
};
